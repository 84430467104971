import { Styles } from '@types'
import { Form, Input, Modal, Select, Image, InputNumber, notification } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Images, Products } from 'gadjet-v2-types/dist/model'
import { creditType } from 'gadjet-v2-types/dist/type/label'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import formRule from '@utils/formRule'

import ProductAPI from '@apis/branch/product'

import { RootState } from '@reducers/index'

import Loading from '@components/molecules/Loading'
import UploadButton from '@components/molecules/UploadButton'

type Props = {
  hqId: number
  branchId: number
  productId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function ProductFormModal({ hqId, branchId, productId, visible, onClose, onDone }: Props): JSX.Element {
  const [form] = useForm<Products>()
  const [loading, setLoading] = useState(false)
  const [imageUri, setImageUri] = useState('')
  const option = useSelector((state: RootState) => state.option)

  const onUploadDone = ({ imageId, uri }: Images) => {
    form.setFieldsValue({ imageId })
    setImageUri(uri)
  }

  const onOk = async () => {
    try {
      const values = await form.validateFields()

      if (productId) await ProductAPI.updateProduct({ hqId, branchId, productId }, { product: values })
      if (!productId) ProductAPI.addProduct({ hqId, branchId }, { product: values })
      notification.success({ message: '상품이 추가되었습니다.' })
      setLoading(false)
      onDone()
      onClose()
    } catch (err) {
      setLoading(false)
    }
  }

  const getProduct = async () => {
    if (!productId) return
    setLoading(true)
    const { data } = await ProductAPI.getProductDetail({ hqId, branchId, productId })
    form.setFieldsValue(data)

    if (data.image) setImageUri(data.image.uri)
    setLoading(false)
  }

  const reset = () => {
    form.resetFields()
  }

  useEffect(() => {
    if (visible) {
      reset()
      if (productId) getProduct()
    }
  }, [visible])

  const initialValues: Partial<Products> = {
    name: '',
    description: '',
    imageId: null,
    price: 0,
    stock: 0,
    availableCreditType: option.creditTypes,
  }

  return (
    <Modal
      title="상품"
      visible={visible}
      onOk={onOk}
      okText="저장"
      confirmLoading={loading}
      onCancel={onClose}
      maskClosable={false}
    >
      <Loading loading={loading}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <Form.Item label="이미지">
            <Image src={imageUri} style={styles.image} />
            <UploadButton.Image
              file={null}
              accept="image/*"
              label=""
              category="product"
              onUploadDone={onUploadDone}
              hideDownload
            />
          </Form.Item>
          <Form.Item name="imageId" hidden>
            <Input />
          </Form.Item>
          <Form.Item label="이름" name="name" required rules={[formRule.required]}>
            <Input />
          </Form.Item>
          <Form.Item label="설명" name="description">
            <Input.TextArea style={styles.textArea} />
          </Form.Item>
          <Form.Item label="가격" name="price" required rules={[formRule.required, formRule.number]}>
            <InputNumber addonAfter="크레딧" min={0} style={styles.inputNumber} />
          </Form.Item>
          <Form.Item label="재고" name="stock" required rules={[formRule.required]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item label="사용가능 크레딧" name="availableCreditType">
            <Select mode="multiple">
              {option.creditTypes.map((type) => (
                <Select.Option key={type} value={type}>
                  {creditType[type]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Loading>
    </Modal>
  )
}

const styles: Styles = {
  image: { width: '200px', height: '200px', marginBottom: '10px' },
  textArea: { height: '100px' },
  inputNumber: { width: '100%' },
}
