import { Styles } from '@types'
import { Modal, Form, Input, notification, Image, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Images, Spaces } from 'gadjet-v2-types/dist/model'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import formRule from '@utils/formRule'
import mask from '@utils/mask'

import SpaceAPI from '@apis/branch/space'

import { RootState } from '@reducers/index'

import Loading from '@components/molecules/Loading'
import UploadButton from '@components/molecules/UploadButton'

type Props = {
  hqId: number
  branchId: number
  spaceId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function SpaceFormModal({ hqId, branchId, spaceId, visible, onClose, onDone }: Props): JSX.Element {
  const [form] = useForm<Spaces>()
  const [loading, setLoading] = useState(false)
  const [imageUri, setImageUri] = useState('')
  const spaceTypes = useSelector((state: RootState) => state.branch.spaceTypes || [])

  const onUploadDone = ({ imageId, uri }: Images) => {
    form.setFieldsValue({ imageId })
    setImageUri(uri)
  }

  const onOk = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()

      if (spaceId) await SpaceAPI.updateSpace({ hqId, branchId, spaceId }, { space: values })
      if (!spaceId) await SpaceAPI.addSpace({ hqId, branchId }, { space: values })

      notification.success({ message: '저장되었습니다.' })

      setLoading(false)
      onDone()
      onClose()
    } catch (err) {
      setLoading(false)
    }
  }

  const getSpace = async () => {
    if (!spaceId) return
    setLoading(true)
    const { data } = await SpaceAPI.getSpaceDetail({ hqId, branchId, spaceId })
    form.setFieldsValue(data)
    setImageUri(data?.image?.uri || '')
    setLoading(false)
  }

  const reset = () => {
    form.resetFields()
  }

  useEffect(() => {
    if (visible) {
      reset()
      getSpace()
    }
  }, [visible])

  const initialValues: Partial<Spaces> = {
    name: '',
    imageId: null,
    area: '',
    capacity: 0,
    price: 0,
    spaceTypeId: undefined,
  }

  return (
    <Modal
      title="공간"
      okText="저장"
      onOk={onOk}
      confirmLoading={loading}
      visible={visible}
      onCancel={onClose}
      maskClosable={false}
    >
      <Loading loading={loading}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <Form.Item label="이미지">
            <Image src={imageUri} style={styles.image} />
            <UploadButton.Image
              file={null}
              accept="image/*"
              label=""
              category="product"
              onUploadDone={onUploadDone}
              hideDownload
            />
          </Form.Item>
          <Form.Item name="imageId" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="spaceTypeId" label="유형" required rules={[formRule.required]}>
            <Select defaultValue="default">
              <Select.Option value="default" disabled>
                공간유형을 선택해주세요.
              </Select.Option>
              {spaceTypes.map((st) => (
                <Select.Option key={st.spaceTypeId} value={st.spaceTypeId}>
                  {st.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="name" label="이름" required rules={[formRule.required]}>
            <Input />
          </Form.Item>
          <Form.Item name="area" label="면적" required rules={[formRule.required]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="capacity"
            label="수용인원"
            required
            rules={[formRule.required, formRule.number, formRule.positiveNumber]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="price"
            label="이용료"
            required
            rules={[formRule.required, formRule.price, formRule.positiveNumber]}
            extra="부가세 미포함 금액"
            {...mask.krw}
          >
            <Input addonAfter="원" />
          </Form.Item>
        </Form>
      </Loading>
    </Modal>
  )
}

const styles: Styles = {
  image: { width: '200px', height: '200px' },
  textArea: { height: '100px' },
}
