import { Styles } from '@types'
import { Button, Table, TableColumnType, TablePaginationConfig } from 'antd'
import { Tenants } from 'gadjet-v2-types/dist/model'
import { Pagination } from 'gadjet-v2-types/dist/type'

import { BranchTenantAction } from '@pages/Branch/TenantPage'

import TableTotalRows from '@components/atoms/TableTotalRows'
import TableWrapper from '@components/atoms/TableWrapper'

type Props = {
  loading: boolean
  total: number
  pagination: Pagination
  tenants: Tenants[]
  onChangePagination: (pagination: Pagination) => void
  onAction: (action: BranchTenantAction, tenantId: number) => void
}
export default function BranchTenantListTable({
  loading,
  total,
  pagination,
  tenants,
  onChangePagination,
  onAction,
}: Props): JSX.Element {
  const onChange = (_pagination: TablePaginationConfig) => onChangePagination({ ...pagination, ..._pagination })

  const columns: TableColumnType<Tenants>[] = [
    { key: 'name', dataIndex: 'name', title: '이름' },
    { key: 'email', dataIndex: 'email', title: '이메일' },
    {
      key: 'tenantId',
      dataIndex: 'tenantId',
      title: '계약',
      render: (_, { tenantId }) => <Button onClick={() => onAction('contract', tenantId)}>계약</Button>,
    },
    {
      key: 'tenantId',
      dataIndex: 'tenantId',
      title: '크레딧',
      render: (_, { tenantId }) => <Button onClick={() => onAction('credit', tenantId)}>크레딧</Button>,
    },
    {
      key: 'tenantId',
      dataIndex: 'tenantId',
      title: '대관/대여',
      render: (_, { tenantId }) => <Button onClick={() => onAction('rental', tenantId)}>대관/대여</Button>,
    },
    {
      key: 'tenantId',
      dataIndex: 'tenantId',
      title: '멤버',
      render: (_, { tenantId }) => <Button onClick={() => onAction('member', tenantId)}>멤버</Button>,
    },
  ]

  return (
    <TableWrapper>
      <Table<Tenants>
        loading={loading}
        style={styles.table}
        scroll={{ x: true }}
        rowKey={({ tenantId }) => `${tenantId}`}
        dataSource={tenants}
        columns={columns}
        onChange={onChange}
        pagination={{
          total,
          position: ['bottomCenter'],
          current: pagination.current,
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          showTotal: (total) => <TableTotalRows total={total} />,
        }}
      />
    </TableWrapper>
  )
}

const styles: Styles = {
  table: { whiteSpace: 'pre-wrap', wordBreak: 'keep-all' },
}
