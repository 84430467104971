import { MoreOutlined } from '@ant-design/icons'
import { Styles } from '@types'
import { Button, Dropdown, Menu, Image, Table, TableColumnType, TablePaginationConfig, Tag } from 'antd'
import dayjs from 'dayjs'
import { Rentals } from 'gadjet-v2-types/dist/model'
import { CreditType, Pagination } from 'gadjet-v2-types/dist/type'
import { creditType } from 'gadjet-v2-types/dist/type/label'

import { krw } from '@utils/format'

import { RentalActionType } from '@pages/Branch/RentalPage'

import ColoredSpaceLabel from '@components/atoms/ColoredSpaceLabel'
import TableTotalRows from '@components/atoms/TableTotalRows'
import TableWrapper from '@components/atoms/TableWrapper'

type Props = {
  loading: boolean
  total: number
  pagination: Pagination
  rentals: Rentals[]
  onAction: (action: RentalActionType, rentalId: number) => void
  onChangePagination: (pagination: Pagination) => void
}
export default function BranchRentalListTable({
  loading,
  total,
  pagination,
  rentals,
  onAction,
  onChangePagination,
}: Props): JSX.Element {
  const onChange = (_pagination: TablePaginationConfig) => onChangePagination({ ...pagination, ..._pagination })

  const columns: TableColumnType<Rentals>[] = [
    {
      key: 'imageId',
      dataIndex: 'imageId',
      title: '사진',
      width: '120px',
      render: (_, { image }) => <Image style={styles.image} src={image?.uri || ''} alt="rental" />,
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: '이름',
      width: '200px',
      render: (_, { name, colorInfo }) => <ColoredSpaceLabel colorInfo={colorInfo} name={name} />,
    },

    {
      key: 'rentalId',
      dataIndex: 'rentalId',
      title: '이용시간',
      width: '200px',
      render: (_, { startTime, endTime, weekendFlag }) => {
        const isAllTime = startTime === '00:00' && endTime === '23:30'
        if (isAllTime)
          return (
            <>
              <span>00:00 - 24:00 </span> {weekendFlag && <Tag>주말가능</Tag>}
            </>
          )
        return (
          <>
            <span>
              {dayjs(startTime, 'HH:mm').format('HH:mm')} - {dayjs(endTime, 'HH:mm').add(30, 'minutes').format('HH:mm')}{' '}
            </span>
            {weekendFlag && <Tag>주말가능</Tag>}
          </>
        )
      },
    },
    {
      key: 'price',
      dataIndex: 'price',
      title: '크레딧/30분',
      align: 'right',
      width: '150px',
      render: (price) => krw(price, true),
    },

    {
      key: 'availableCreditType',
      dataIndex: 'availableCreditType',
      title: '결제 크레딧',
      width: '100px',
      render: (availableCreditType: CreditType[]) =>
        availableCreditType.map((type) => <Tag key={type}>{creditType[type]}</Tag>),
    },
    { key: 'description', dataIndex: 'description', title: '설명' },
    {
      key: 'rentalId',
      dataIndex: 'rentalId',
      title: '',
      width: '80px',
      align: 'center',
      render: (rentalId) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="form" onClick={() => onAction('update', rentalId)}>
                수정
              </Menu.Item>
              <Menu.Item key="delete" onClick={() => onAction('delete', rentalId)}>
                삭제
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Button icon={<MoreOutlined />} size="large" type="text" />
        </Dropdown>
      ),
    },
  ]

  return (
    <TableWrapper>
      <Table<Rentals>
        loading={loading}
        style={styles.table}
        scroll={{ x: true }}
        rowKey={({ rentalId }) => `${rentalId}`}
        dataSource={rentals}
        columns={columns}
        onChange={onChange}
        pagination={{
          total,
          position: ['bottomCenter'],
          current: pagination.current,
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          showTotal: (total) => <TableTotalRows total={total} />,
        }}
      />
    </TableWrapper>
  )
}

const styles: Styles = {
  table: { whiteSpace: 'pre-wrap', wordBreak: 'keep-all' },
  image: { width: '100px', height: '100px', objectFit: 'cover', borderRadius: '8px' },
}
