import { Styles } from '@types'
import { Form, Select, Input, FormInstance } from 'antd'
import { Contracts, ContractSpaces, Spaces } from 'gadjet-v2-types/dist/model'
import { useMemo } from 'react'

import formRule from '@utils/formRule'
import { krw } from '@utils/format'
import mask from '@utils/mask'

import FormListTable, { FormListTableColumn } from '@components/molecules/FormListTable'
import TableColumnItem from '@components/molecules/TableColumnItem'

import TotalPriceFooter from '../TotalPriceFooter'

type Props = {
  form: FormInstance<Contracts>
  spaces: Spaces[]
  loading: boolean
}

export default function ContractFeeSpaceList({ form, spaces, loading }: Props): JSX.Element {
  const findSpace = (spaceId: number) => spaces.find((s) => s.spaceId === spaceId)

  const onChangeSpaceId = (spaceId: number, index: number) => {
    const space = findSpace(spaceId)
    if (!space) return

    const _contractSpaces = form.getFieldValue('contractSpaces')
    form.setFieldsValue({
      contractSpaces: [
        ..._contractSpaces.slice(0, index),
        { spaceId, name: space.name, price: space.price },
        ..._contractSpaces.slice(index + 1),
      ],
    })
  }

  const spaceOptions = useMemo(() => spaces.map((s) => ({ label: s.name, value: s.spaceId })), [spaces])
  const spaceColumns = useMemo(
    (): FormListTableColumn[] => [
      {
        label: '공간',
        width: '25%',
        render: (field, index) => (
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const _value = getFieldValue('contractSpaces')[index]
              return (
                <Form.Item name={[field.name, 'spaceId']} rules={[formRule.required]} style={styles.formItem}>
                  <Select<number>
                    style={styles.select}
                    value={_value.spaceId}
                    onChange={(value) => onChangeSpaceId(value, index)}
                    options={spaceOptions}
                    loading={loading}
                    showSearch
                    optionFilterProp="label"
                  />
                </Form.Item>
              )
            }}
          </Form.Item>
        ),
      },
      {
        label: '정가',
        width: '15%',
        render: (field, index) => (
          <Form.Item shouldUpdate style={styles.formItem}>
            {({ getFieldValue }) => {
              const formSpaces = getFieldValue('contractSpaces')
              const { spaceId } = formSpaces[index] || { spaceId: 0 }
              const space = findSpace(spaceId)
              return krw(space?.price || 0)
            }}
          </Form.Item>
        ),
      },
      {
        label: '계약가',
        width: '25%',
        render: (field) => (
          <Form.Item
            isListField
            style={styles.formItem}
            name={[field.name, 'price']}
            rules={[formRule.required, formRule.price, formRule.positiveNumber]}
            initialValue={0}
            {...mask.krw}
          >
            <Input addonAfter="원" />
          </Form.Item>
        ),
      },
      {
        label: '할인',
        width: '25%',
        render: (field, index) => (
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const formSpaces = getFieldValue('contractSpaces')
              const { spaceId, price } = formSpaces[index] || { spaceId: 0, price: 0 }
              const space = findSpace(spaceId)
              const spacePrice = space?.price || 0
              const discountPrice = krw(spacePrice - price)
              const discountRate = spacePrice === 0 ? '0%' : `${Math.round(((spacePrice - price) / spacePrice) * 100)}%`
              return (
                <>
                  <TableColumnItem label="할인금액" value={`${discountPrice}`} />
                  <TableColumnItem label="할인율(반올림)" value={`${discountRate}`} />
                </>
              )
            }}
          </Form.Item>
        ),
      },
    ],
    [spaces, loading]
  )
  const SpaceFooter = () => {
    return (
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const contractSpaces: ContractSpaces[] = getFieldValue('contractSpaces') || []
          const totalPrice = contractSpaces
            .map(({ price }) => price)
            .reduce((a, b) => Number(a || 0) + Number(b || 0), 0)
          return <TotalPriceFooter price={totalPrice} />
        }}
      </Form.Item>
    )
  }

  const defaultValue = useMemo(
    () => ({
      spaceId: spaces[0]?.spaceId || null,
      name: spaces[0]?.name,
      price: 0,
    }),
    [spaces]
  )

  return (
    <FormListTable
      columns={spaceColumns}
      name="contractSpaces"
      title="공간"
      footer={SpaceFooter}
      defaultValue={defaultValue}
    />
  )
}

const styles: Styles = {
  select: { width: '100%' },
  formItem: { paddingTop: '24px' },
}
