import { Styles } from '@types'

import { krw } from '@utils/format'

type Props = { price: number }

export default function TotalPricefooter({ price }: Props): JSX.Element {
  return (
    <div>
      <div style={styles.totalPrice}>{krw(price)}</div>
      <div style={styles.vat}>+부가세 {krw(Math.floor(price * 0.1))}</div>
    </div>
  )
}

const styles: Styles = {
  totalPrice: { fontSize: '24px', fontWeight: 'bold', textAlign: 'right' },
  vat: { fontSize: '18px', fontWeight: 'normal', opacity: 0.6, textAlign: 'right' },
}
