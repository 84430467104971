import * as TENANT from 'gadjet-v2-types/dist/api/branch/tenant'

import axios from '@utils/axios'

const TenantAPI = {
  // 입주사 조회
  getTenants: async (
    { hqId, branchId }: TENANT.GET_LIST_PARAMS,
    query: TENANT.GET_LIST_QUERY
  ): Promise<{ data: TENANT.GET_LIST_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/tenants`, { params: query }),

  // 입주사 입주원 조회
  getTenantUsers: async (
    { hqId, branchId, tenantId }: TENANT.GET_USERS_PARAMS,
    query: TENANT.GET_USERS_QUERY
  ): Promise<{ data: TENANT.GET_USERS_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/tenants/${tenantId}/users`, { params: query }),

  // 입주사 계약 조회
  getTenantContracts: async (
    { hqId, branchId, tenantId }: TENANT.GET_CONTRACTS_PARAMS,
    query: TENANT.GET_CONTRACTS_QUERY
  ): Promise<{ data: TENANT.GET_CONTRACTS_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/tenants/${tenantId}/contracts`, { params: query }),

  // 입주사 대여대관 내역 조회
  getTenantRentalReservations: async (
    { hqId, branchId, tenantId }: TENANT.GET_RENTAL_RESERVATIONS_PARAMS,
    query: TENANT.GET_RENTAL_RESERVATIONS_QUERY
  ): Promise<{ data: TENANT.GET_RENTAL_RESERVATIONS_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/tenants/${tenantId}/rental-reservations`, { params: query }),

  // 입주사 상품 구매내역 조회
  getTenantProductSales: async (
    { hqId, branchId, tenantId }: TENANT.GET_PRODUCT_SALES_PARAMS,
    query: TENANT.GET_PRODUCT_SALES_QUERY
  ): Promise<{ data: TENANT.GET_PRODUCT_SALES_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/tenants/${tenantId}/product-sales`, { params: query }),

  // 입주사 크레딧 조회
  getTenantCredits: async (
    { hqId, branchId, tenantId }: TENANT.GET_CREDITS_PARAMS,
    query: TENANT.GET_CREDITS_QUERY
  ): Promise<{ data: TENANT.GET_CREDITS_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/tenants/${tenantId}/credits`, { params: query }),

  // 입주사 크레딧 로그 조회
  getTenantCreditLogs: async (
    { hqId, branchId, tenantId }: TENANT.GET_CREDIT_LOGS_PARAMS,
    query: TENANT.GET_CREDIT_LOGS_QUERY
  ): Promise<{ data: TENANT.GET_CREDIT_LOGS_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/tenants/${tenantId}/credit-logs`, { params: query }),

  // 입주사 다운로드
  exportTenants: async (
    { hqId, branchId }: TENANT.EXPORT_PARAMS,
    query: TENANT.EXPORT_QUERY
  ): Promise<{ data: TENANT.EXPORT_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/tenants/export`, { params: query }),

  // 입주사 크레딧 지급
  addTenantCredit: async (
    { hqId, branchId, tenantId }: TENANT.ADD_CREDITS_PARAMS,
    { credit }: TENANT.ADD_CREDITS_BODY
  ): Promise<{ data: TENANT.ADD_CREDITS_RESPONSE }> =>
    axios.post(`/hqs/${hqId}/branches/${branchId}/tenants/${tenantId}/credits`, { credit }),

  // 입주사 멤버 초대
  inviteTenantMembers: async (
    { hqId, branchId, tenantId }: TENANT.INVITE_USERS_PARAMS,
    { phone }: TENANT.INVITE_USERS_BODY
  ): Promise<{ data: TENANT.INVITE_USERS_RESPONSE }> =>
    axios.post(`/hqs/${hqId}/branches/${branchId}/tenants/${tenantId}/invite`, { phone }),
}

export default TenantAPI
