import * as RENTAL from 'gadjet-v2-types/dist/api/branch/rental'

import axios from '@utils/axios'

const RentalAPI = {
  // 대관대여 상세조회
  getRentalDetail: async ({
    hqId,
    branchId,
    rentalId,
  }: RENTAL.GET_DETAIL_PARAMS): Promise<{ data: RENTAL.GET_DETAIL_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/rentals/${rentalId}`),

  // 대관대여 조회
  getRentals: async (
    { hqId, branchId }: RENTAL.GET_LIST_PARAMS,
    query: RENTAL.GET_LIST_QUERY
  ): Promise<{ data: RENTAL.GET_LIST_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/rentals`, { params: query }),

  // 대관대여 추가
  addRental: async (
    { hqId, branchId }: RENTAL.ADD_PARAMS,
    { rental }: RENTAL.ADD_BODY
  ): Promise<{ data: RENTAL.ADD_RESPONSE }> => axios.post(`/hqs/${hqId}/branches/${branchId}/rentals`, { rental }),

  // 대관대여 수정
  updateRental: async (
    { hqId, branchId, rentalId }: RENTAL.UPDATE_PARAMS,
    { rental }: RENTAL.UPDATE_BODY
  ): Promise<{ data: RENTAL.UPDATE_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/rentals/${rentalId}`, { rental }),

  // 대관대여 삭제
  deleteRental: async ({ hqId, branchId, rentalId }: RENTAL.DELETE_PARAMS): Promise<{ data: RENTAL.DELETE_RESPONSE }> =>
    axios.delete(`/hqs/${hqId}/branches/${branchId}/rentals/${rentalId}`),

  // 대관대여 예약 조회
  getRentalReservations: async (
    { hqId, branchId }: RENTAL.GET_RESERVATIONS_PARAMS,
    query: RENTAL.GET_RESERVATIONS_QUERY
  ): Promise<{ data: RENTAL.GET_RESERVATIONS_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/rental-reservations`, { params: query }),

  // 대관대여 새 예약
  addRentalReservations: async (
    { hqId, branchId, rentalId }: RENTAL.ADD_RESERVATION_PARAMS,
    { rentalReservation }: RENTAL.ADD_RESERVATION_BODY
  ): Promise<{ data: RENTAL.ADD_RESERVATION_RESPONSE }> =>
    axios.post(`/hqs/${hqId}/branches/${branchId}/rentals/${rentalId}/rental-reservations`, { rentalReservation }),

  // 대관대여 예약 수정
  updateRentalReservation: async (
    { hqId, branchId, rentalId, rentalReservationId }: RENTAL.UPDATE_RESERVATION_PARAMS,
    { rentalReservation }: RENTAL.UPDATE_RESERVATION_BODY
  ): Promise<{ data: RENTAL.UPDATE_RESERVATION_RESPONSE }> =>
    axios.put(`/hqs/${hqId}/branches/${branchId}/rentals/${rentalId}/rental-reservations/${rentalReservationId}`, {
      rentalReservation,
    }),

  // 대관대여 예약 취소
  deleteRentalReservation: async ({
    hqId,
    branchId,
    rentalId,
    rentalReservationId,
  }: RENTAL.DELETE_RESERVATION_PARAMS): Promise<{ data: RENTAL.DELETE_RESERVATION_RESPONSE }> =>
    axios.delete(`/hqs/${hqId}/branches/${branchId}/rentals/${rentalId}/rental-reservations/${rentalReservationId}`),

  // 대관대여 예약 다운로드
  exportRentalReservations: async (
    { hqId, branchId }: RENTAL.EXPORT_RESERVATIONS_PARAMS,
    query: RENTAL.EXPORT_RESERVATIONS_QUERY
  ): Promise<{ data: RENTAL.EXPORT_RESERVATIONS_RESPONSE }> =>
    axios.get(`/hqs/${hqId}/branches/${branchId}/rental-reservations/export`, { params: query }),
}

export default RentalAPI
