import useMenu from '@hooks/useMenu'
import useModal from '@hooks/useModal'
import { Pagination } from 'gadjet-v2-types/dist/type'
import { AdditionSearch } from 'gadjet-v2-types/dist/type/search'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  setAdditionPagination,
  setAdditionSearch,
  searchAdditions,
  setAdditionDefault,
} from '@reducers/branch/addition.reducer'
import { RootState } from '@reducers/index'

import AdditionContractModal from './organisms/AdditionContractModal'
import AdditionDeleteModal from './organisms/AdditionDeleteModal'
import AdditionFormModal from './organisms/AdditionFormModal'
import BranchAdditionListTable from './organisms/ListTable'
import BranchAdditionSearchBox from './organisms/SearchBox'
import BranchAdditionTitle from './organisms/Title'

export type BranchAdditionAction = 'add' | 'update' | 'delete' | 'contract'

export default function AdditionPage(): JSX.Element {
  const dispatch = useDispatch()
  const { hqId, branchId } = useMenu()
  const { loading, search, pagination, searchResult } = useSelector((state: RootState) => state.addition)

  const [formModal, onVisibleFormModal, onCloseFormModal] = useModal<{ additionId?: number }>({ visible: false })
  const [deleteModal, onVisibleDeleteModal, onCloseDeleteModal] = useModal<{ additionId?: number }>({ visible: false })
  const [contractModal, onVisibleContractModal, onCloseContractModal] = useModal<{ additionId?: number }>({
    visible: false,
  })

  const onChangeSearch = (_search: Partial<AdditionSearch>) => dispatch(setAdditionSearch(_search))
  const onChangePagination = (_pagination: Pagination) => {
    dispatch(setAdditionPagination(_pagination))
    dispatch(searchAdditions(hqId, branchId))
  }
  const onSearch = () => {
    dispatch(setAdditionPagination({ current: 1 }))
    dispatch(searchAdditions(hqId, branchId))
  }
  const onResetAndSearch = useCallback(() => dispatch(setAdditionDefault(hqId, branchId)), [hqId, branchId])
  const onDone = () => onResetAndSearch()

  const onAction = (action: BranchAdditionAction, additionId?: number) => {
    if (action === 'add') onVisibleFormModal({ additionId: undefined })
    if (action === 'update') onVisibleFormModal({ additionId })
    if (action === 'delete') onVisibleDeleteModal({ additionId })
    if (action === 'contract') onVisibleContractModal({ additionId })
  }

  useEffect(() => {
    if (searchResult.total === 0 || searchResult.branchId !== branchId) onResetAndSearch()
  }, [dispatch, hqId, branchId])

  return (
    <div>
      <BranchAdditionSearchBox search={search} onChangeSearch={onChangeSearch} onSearch={onSearch} />

      <BranchAdditionTitle onAction={onAction} />

      <BranchAdditionListTable
        loading={loading}
        onChangePagination={onChangePagination}
        pagination={pagination}
        additions={searchResult.additions}
        total={searchResult.total}
        onAction={onAction}
      />

      <AdditionFormModal
        hqId={hqId}
        branchId={branchId}
        additionId={formModal.additionId}
        visible={formModal.visible}
        onClose={onCloseFormModal}
        onDone={onDone}
      />
      <AdditionDeleteModal
        hqId={hqId}
        branchId={branchId}
        additionId={deleteModal.additionId}
        visible={deleteModal.visible}
        onClose={onCloseDeleteModal}
        onDone={onDone}
      />
      <AdditionContractModal
        hqId={hqId}
        branchId={branchId}
        additionId={contractModal.additionId}
        visible={contractModal.visible}
        onClose={onCloseContractModal}
      />
    </div>
  )
}
